import { AppRouteProps } from "@vdt/component";
import React from "react";

const DevicesUserDetailsContainer = React.lazy(() => import('./containers/DevicesUserDetailsContainer'))
export const DevicesUserDetailsRoutes: Array<AppRouteProps> = [
    {
        path: 'devices',
        title: 'Generic.User.Details.Menu.Label.Devices',
        element: <DevicesUserDetailsContainer />
    }
]