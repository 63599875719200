import { AppRouteProps } from "@vdt/component";
import React from "react";
import { AboutUserDetailsRoutes } from "./views/about/AboutUserDetailsRoutes";
import { ParamsUserDetailsRoutes } from "./views/params/ParamsUserDetailsRoutes";
import { DevicesUserDetailsRoutes } from "./views/device/DevicesUserDetailsRoutes";

const UserDetailsContainer = React.lazy(() => import('./containers/UserDetailsContainer'))
export const UserDetailsRoutes: Array<AppRouteProps> = [
    {
        path: 'user/:id',
        title: '{{UserNameFull}}',
        element: <UserDetailsContainer />,
        children: [
            ...AboutUserDetailsRoutes,
            ...ParamsUserDetailsRoutes,
            ...DevicesUserDetailsRoutes,
        ]
    }
]