import { AppRouteProps } from "@vdt/component";
import React from "react";

const OrganisationContainer = React.lazy(
    () => import("./container/OrganisationContainer")
);

export const OrganisationRoutes: Array<AppRouteProps> = [
    {
        path: "signup/organisation",
        title: "Generic.Auth.Organisation.title",
        element: <OrganisationContainer />,
    },
];
