import { useTranslationRoute } from "@vdt/component";
import { Link, Outlet } from "react-router-dom";

const ClientLayout = () => {
    const tr = useTranslationRoute();

    return (
        <div className="flex bg-secondary text-white font-['Helvetica_Neue']">
            <aside className="hidden lg:block bg-gradient-to-b from-primary to-secondary w-[273px] h-screen fixed left-0 top-0 p-5">
                <div className="sticky w-32">
                    <Link to={tr("")}>
                        <img
                            src="/assets/img/spray-logo.png"
                            alt="Logo"
                            className="w-full"
                        />
                    </Link>
                </div>
            </aside>
            <main className="lg:pl-[293px] lg:flex-grow h-screen overflow-y-auto pr-5 pl-5 py-5">
                <Outlet />
            </main>
        </div>
    );
};

export default ClientLayout;
