import { AppRouteProps } from '@vdt/component'
import React from 'react'
import { CampagneCreateRoutes } from 'views/create/CampagneCreateRoutes'
import { CampagneDetailsRoutes } from 'views/details/CampagneDetailsRoutes'
import { CampagneListRoutes } from 'views/list/CampagneListRoutes'

const CampagneContainer = React.lazy(() => import('./containers/CampagneContainer'))

export const CampagneRoutes = [
    {
        path: 'campagne',
        title: 'App.VPostal.Campagne.List.Title',
        element: <CampagneContainer/>,
        children: [
            ...CampagneListRoutes,
            ...CampagneDetailsRoutes,
            ...CampagneCreateRoutes
        ]
    },
] as Array<AppRouteProps>
