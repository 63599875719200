import { AppRouteProps } from "@vdt/component";
import React from "react";

const CampagneContainer = React.lazy(() => import("./container/CampagneListContainer"))

export const CampagneListRoutes: Array<AppRouteProps> = [
    {
        path: "",
        element: <CampagneContainer/>,
        title: "App.VPostal.Campagne.List.Title",
    }
] 