import { AppRouteProps } from "@vdt/component";
import React from "react";

const CampagneDetailsContainer = React.lazy(() => import('./containers/CampagneDetailsContainer'))

export const CampagneDetailsRoutes: Array<AppRouteProps> = [
    {
        title: "{{CampagneDetailsTitle}}",
        path: ":id",
        element: <CampagneDetailsContainer/>
    }
]