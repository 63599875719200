import { AppRouteProps } from '@vdt/component'
import React from 'react'

const ClientContainer = React.lazy(() => import("./container/ClientContainer"));

export const ClientRoutes: Array<AppRouteProps> = [
    {
        path: "",
        element: <ClientContainer />,
    },
];