import * as React from "react";
import { AppRouteProps } from "@vdt/component";
import { OrganisationRoutes } from "./views/organisation/OrganisationRoutes";

const SignupContainer = React.lazy(() => import("./container/SignupContainer"));

export const SignupRoutes: Array<AppRouteProps> = [
    {
        path: "signup",
        title: "Harea.Signup.Title",
        element: <SignupContainer />,
    },
    ...OrganisationRoutes
];
